import React, { useCallback, useEffect } from 'react';
import { useSpring } from 'react-spring/web';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { PopupContent } from '../../state/application/actions';
import { useRemovePopup } from '../../state/application/hooks';
import TransactionPopup from './TransactionPopup';
import ErrorPopup from './ErrorPopup';
import DataPopup1 from './DataPopup1/DataPopup1';
import ConfirmPopup from './ConfirmPopup/ConfirmPopup';
import ContextPopup from './ContextPopup/ContextPopup';

export const StyledClose = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  :hover {
    cursor: pointer;
  }
`;
export const Popup = styled.div`
  display: inline-block;
  z-index: 200;
  width: 100%;
  padding: 1em;
  position: relative;
  padding: 20px;
  padding-right: 35px;
  overflow: hidden;
  border-radius: 5px;
  background: rgb(33, 36, 41);
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px,
    rgba(0, 0, 0, 0.04) 0px 16px 24px, rgba(0, 0, 0, 0.01) 0px 24px 32px;

  @media (max-width: 768px) {
    display: block;
    min-width: 290px;
  }
`;
const Fader = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.color.secondary};
`;

const AnimatedFader = animated(Fader);

interface PopupItemProps {
  removeAfterMs: number | null;
  content: PopupContent;
  popKey: string;
}

const PopupItem: React.FC<PopupItemProps> = ({ removeAfterMs, content, popKey }) => {
  const removePopup = useRemovePopup();
  const removeThisPopup = useCallback(
    () => removePopup(popKey, 'notification'),
    [popKey, removePopup],
  );
  let timeRemove: number;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = (event: MouseEvent) => {
    if (
      wrapperRef !== null &&
      !wrapperRef?.current?.contains(event.target as Node) &&
      wrapperRef?.current?.classList.contains('context-popup')
    )
      removePopup('', 'context');
  };

  const wrapperRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    document.addEventListener('click', handleClick);

    if (timeRemove === null)
      return () => {
        document.removeEventListener('click', handleClick);
      };

    const timeout = setTimeout(() => {
      removeThisPopup();
    }, timeRemove);

    return () => {
      document.removeEventListener('click', handleClick);
      clearTimeout(timeout);
    };
  }, [handleClick, removeThisPopup, timeRemove]);

  let popupContent;

  if ('txn' in content) {
    const {
      txn: { success, summary },
    } = content;
    popupContent = <TransactionPopup success={success} summary={summary} />;
    timeRemove = 3000;
  }
  if ('error' in content) {
    const {
      error: { message, title },
    } = content;
    popupContent = <ErrorPopup message={message} title={title} />;
    timeRemove = 3000;
  }
  if ('view' in content) {
    timeRemove = null;
  }
  if ('confirm' in content) {
    timeRemove = null;
  }
  if ('context' in content) {
    timeRemove = null;
  }

  const faderStyle = useSpring({
    from: { width: '100%' },
    to: { width: '0%' },
    config: { duration: removeAfterMs ?? undefined },
  });

  if ('txn' in content || 'error' in content) {
    return (
      <Popup>
        <StyledClose onClick={removeThisPopup}>
          <i color="#ffffff" className="fas  fa-times"></i>
        </StyledClose>
        {popupContent}
        {removeAfterMs !== null ? <AnimatedFader style={faderStyle} /> : null}
      </Popup>
    );
  } else if ('view' in content) {
    return (
      <div ref={wrapperRef} className="data-popup">
        <DataPopup1
          keyPopup={popKey}
          width={content.view.width}
          height={content.view.height}
          popupTitle={content.view.title}
          data={content.view.data}
          isContext={content.view.isContext}
          posX={content.view.posX}
          posY={content.view.posY}
          isManual={content.view.isManualRemove}
        />
      </div>
    );
  } else if ('confirm' in content) {
    return (
      <div ref={wrapperRef} className="confirm-popup">
        <ConfirmPopup
          width={content.confirm.width}
          height={content.confirm.height}
          question={content.confirm.question}
          listActionButton={content.confirm.listActionButton}
          posX={content.confirm.posX}
          posY={content.confirm.posY}
        />
      </div>
    );
  } else if ('context' in content) {
    return (
      <div ref={wrapperRef} className="context-popup">
        <ContextPopup
          width={content.context.width}
          height={content.context.height}
          posX={content.context.posX}
          posY={content.context.posY}
          listActionButton={content.context.listActionButton}
        />
      </div>
    );
  }
};

export default PopupItem;
