import './Base.css';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import TrackingComponent from 'src/components/TrackingComponent/TrackingComponent';
import useQuery from 'src/hooks/useQuery';

const Base: React.FC = () => {
  //Value
  const query = useQuery();
  const tid = query.get('tid');
  console.log(tid);

  //state
  const [keyword, setKeyword] = useState(tid);
  const [trackingIdList, setTrackingIdList] = useState<string[]>([]);

  //Function
  const addPopup = useAddPopup();
  const onChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const enterFunction = (event) => {
    if (event.keyCode === 13) {
      //Do whatever when esc is pressed
      onOderTracking();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onOderTracking = () => {
    if (keyword !== '' && keyword !== null) {
      const arr = keyword.split(',');
      setTrackingIdList(arr);
      console.log(arr);
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Bạn chưa nhập Tracking ID',
        },
      });
      setTrackingIdList([]);
    }
  };

  //useEffect
  useEffect(() => {
    document.addEventListener('keydown', enterFunction, false);
    return () => {
      document.removeEventListener('keydown', enterFunction, false);
    };
  }, [enterFunction]);

  useEffect(() => {
    if (tid !== null && tid !== '') {
      onOderTracking();
    }
  }, []);

  return (
    <div className="home p-0 m-0">
      <div className="order-wrapper">
        <div className="base title">
          <i>
            Mời quý khách nhập vã vận đơn để tra cứu(Tra nhiều bill bằng cách thêm dấu phẩy giữa
            các bill VD: 392773302,392447363)
          </i>
        </div>
        <div className="base">
          <input
            className="base-search-input"
            type="text"
            value={keyword}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChangeKeyword(event);
            }}
          />
          <div className="btn-search-home-container">
            <div
              className="btn-search-home"
              onClick={() => {
                onOderTracking();
              }}
            >
              TÌM ĐƠN
            </div>
          </div>
        </div>
        <div className="base value">
          {trackingIdList.length > 0
            ? trackingIdList.map((value, index) => {
                return <TrackingComponent key={index} trackingId={value} />;
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default Base;
